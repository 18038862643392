<template>
  <div class="special-ico">
    <Header titleurl="special" />
    <div class="content">
      <div class="wrap-w">
        <div class="cart-filter-bar">
          <ul class="switch-cart">
            <li class="switch-cart-item"><a href="#none"><em>全部商品</em><span class="number">15</span></a></li>
          </ul>
          <div class="cart-store"><span class="label">配送至：</span>
            <div id="jdarea" class="ui-area-wrap ui-area-oversea-mode">
              <div class="ui-area-text-wrap">
                <div class="ui-area-text" data-id="7-489-3576-51775" title="河南漯河市源汇区城区">河南漯河市源汇区城区</div><b></b>
              </div>
              <div class="ui-area-content-wrap ui-area-common-area-mode ui-area-w-max" style="left: 0px;">
                <div class="ui-area-close"></div>
                <div class="ui-area-module-wrap ui-area-common-wrap">
                  <div class="ui-area-module-title">常用地址<b></b></div>
                  <div class="ui-area-common-content">
                    <ul>
                      <li><a data-aid="2471163469" href="javascript:" title="翟庄街道     春和家园"
                          data-localid="7-489-493-51774"><span class="ui-area-common-c-i-l">蒋丹霞</span><span
                            class="ui-area-common-c-i-r">翟庄街道 春和家园</span></a></li>
                      <li><a data-aid="1330635505" href="javascript:" title="五一路588号凯业商务"
                          data-localid="7-489-3576-51775"><span class="ui-area-common-c-i-l">李兴</span><span
                            class="ui-area-common-c-i-r">五一路588号凯业商务</span></a></li>
                    </ul>
                  </div>
                </div>
                <div class="ui-area-module-wrap ui-area-select-wrap ui-area-open">
                  <div class="ui-area-module-title">选择新地址<b></b></div>
                  <div class="ui-area-select-main-wrap">

                    <el-cascader ref="addressCascader" style="width:180px" class="city" :options="city"
                      v-model="province" @change="oncitycascader" clearable></el-cascader>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="clr"></div>
        <div class="cart-thead">
          <div class="column t-checkbox">
            <div class="cart-checkbox"><input type="checkbox" name="select-all" class="jdcheckbox"
                clstag="pageclick|keycount|Shopcart_CheckAll|0"></div>全选
          </div>
          <div class="column t-goods">商品</div>
          <div class="column t-props">&nbsp;</div>
          <div class="column t-price">单价</div>
          <div class="column t-quantity">数量</div>
          <div class="column t-sum">小计</div>
          <div class="column t-action">操作</div>
        </div>
        <div class="cart-tbody">
          <div class="shop">
            <div class="cart-checkbox"><input type="checkbox" name="checkShop" class="jdcheckbox"
                clstag="pageclick|keycount|Shopcart_CheckShop|0_8888"></div><span class=""><span
                class="shop-coupon"><span class="shop-coupon-btn" id="J_zypromo_btn"
                  clstag="pageclick|keycount|Shopcart_ZYCoupon">优惠券</span></span></span><span class="shop-txt"><a
                href="#!" class="shop-name diy mr10" rel="noreferrer" data-vendorid="8888"
                clstag="pageclick|keycount|Shopcart_Shopid|8888_0">京东自营</a></span>
            <div class="shop-extra-r" data-skustype="2" data-fresh-price="" data-fresh-weightneed=""
              data-fresh-weight="" data-fresh-priceneed="" data-normal-price="5959.00" data-normal-weight="27.66"
              clstag="pageclick|keycount|OrderFree_FreightRule" data-normal-weightneed="2.34"><span class=""><span><i
                    class="icon-confirm"></i><span class="ftx-07">已免运费</span></span></span><a href="//plus.jd.com/index"
                target="_blank" class="ftx-07 ml5">试用PLUS立得1张运费券 &gt;</a></div>
          </div>
          <div class="item-list">
            <div class="item-item item-seleted" id="100002404996" data-sku="100002404996" data-ts="1607581076893"
              data-skuuuid="119413796893864315056386048">
              <div class="item-form">
                <div class="cell p-checkbox">
                  <div class="cart-checkbox"><input type="checkbox" name="checkItem" class="jdcheckbox"
                      clstag="pageclick|keycount|Shopcart_CheckProd|1_100002404996" checked=""><span
                      class="line-circle"></span></div>
                </div>
                <div class="cell p-goods">
                  <div class="goods-item">
                    <div class="p-img "><a href="//item.jd.com/100002404996.html" target="_blank" rel="noreferrer"
                        title="安钛克（Antec）暗黑系-复仇者X（DA601） 中塔钢化玻璃侧透 配赠幻彩ARGB风扇 360水冷电脑游戏机箱"><img
                          src="//img14.360buyimg.com/n0/s80x80_jfs/t1/32555/34/535/410483/5c3db256Ebada2efc/6d602ae4618cb4e3.jpg.dpg"
                          alt="安钛克（Antec）暗黑系-复仇者X（DA601） 中塔钢化玻璃侧透 配赠幻彩ARGB风扇 360水冷电脑游戏机箱"></a></div>
                    <div class="p-msg">
                      <div class="p-name"><a href="//item.jd.com/100002404996.html" target="_blank" rel="noreferrer"
                          title="安钛克（Antec）暗黑系-复仇者X（DA601） 中塔钢化玻璃侧透 配赠幻彩ARGB风扇 360水冷电脑游戏机箱"
                          clstag="pageclick|keycount|Shopcart_Productid|100002404996_1">安钛克（Antec）暗黑系-复仇者X（DA601）
                          中塔钢化玻璃侧透 配赠幻彩ARGB风扇 360水冷电脑游戏机箱</a></div>
                      <div class="p-extend p-extend-new"><span class=""><span class="promise ftx-03 jd-service"><span
                              class="jd-service-icon"></span><span data-tips="选服务"
                              class="ftx-03 jd-service jd-service-text"
                              clstag="pageclick|keycount|Shopcart_ExtendedService">选服务</span></span></span></div>
                    </div>
                  </div>
                </div>
                <div class="cell p-props">
                  <div class="props-txt"><span class="">暗黑系列</span></div>
                  <div class="props-txt"><span class="">复仇者X</span></div>
                </div>
                <div class="cell p-price"><span class="p-price-cont">￥459.00</span>
                  <p class="mt5"></p>
                </div>
                <div class="cell p-quantity">
                  <div class="cart-number quantity "><button class="cart-number-dec is-disabled" disabled=""><i
                        class="cart-icon-subt"></i></button>
                    <div class="cart-input"><input class="cart-input-o" min="1" max="200" value="1"></div><button
                      class="cart-number-inc"><i class="cart-icon-add"></i></button>
                  </div>
                  <p class="ac ftx03">有货</p>
                </div>
                <div class="cell p-sum"><strong>¥459.00</strong></div>
                <div class="cell p-ops"><a href="#none" class="p-ops-item"
                    clstag="pageclick|keycount|Shopcart_Delete|100002404996">删除</a><a href="#none" class="p-ops-item"
                    clstag="pageclick|keycount|Shopcart_MovetoFollow|100002404996">移入关注</a></div>
              </div>
              <div class="product-extra mb10">
                <p class="pec-item"><span class="pec-item-tit fl ftx01 pec-item-tit-plus"><em
                      class="pec-booking-checkbox"></em>免费试用plus会员，享受商品会员价(省¥30.00元)</span><span
                    class="pec-item-tit fl"><a href="//plus.jd.com/index" class="ftx-07 ml20" target="_blank"
                      rel="noreferrer">立即试用&nbsp;&gt;</a></span></p>
              </div>
              <div class="item-line"></div>
            </div>
            <div class="item-combine">
              <div class="item-header">
                <div class="f-txt"><span class="full-icon ">换购<b></b></span><span class="vertical-gap"></span><span
                    class="ftx06 ml5">已满1件,可低价换购商品</span><a href="#none" class="hg-btn ftx01 ml5"
                    id="huangou_200033026387">立即换购&nbsp;&gt;</a></div>
                <div class="f-price ar">
                  <p class="ftx07">￥5,500.00</p>
                </div>
              </div>
              <div class="item-item item-seleted" id="5893557" data-sku="5893557" data-ts="1592632752293"
                data-skuuuid="113824395831166568443424768">
                <div class="item-form">
                  <div class="cell p-checkbox">
                    <div class="cart-checkbox"><input type="checkbox" name="checkItem" class="jdcheckbox"
                        clstag="pageclick|keycount|Shopcart_CheckProd|1_5893557"><span class="line-circle"></span></div>
                  </div>
                  <div class="cell p-goods">
                    <div class="goods-item">
                      <div class="p-img "><a href="//item.jd.com/5893557.html" target="_blank" rel="noreferrer"
                          title="神奇牙刷 创意环保小麦秸秆洗漱杯子漱口杯牙杯"><img
                            src="//img20.360buyimg.com/n0/s80x80_jfs/t1/147097/4/14032/136813/5fabbacaE3849eb9d/81783d9c217de975.jpg.dpg"
                            alt="神奇牙刷 创意环保小麦秸秆洗漱杯子漱口杯牙杯"></a></div>
                      <div class="p-msg">
                        <div class="p-name"><a href="//item.jd.com/5893557.html" target="_blank" rel="noreferrer"
                            title="神奇牙刷 创意环保小麦秸秆洗漱杯子漱口杯牙杯" clstag="pageclick|keycount|Shopcart_Productid|5893557_12"><i
                              class="product-icon JD_SUPERMARKET" title=""></i>神奇牙刷 创意环保小麦秸秆洗漱杯子漱口杯牙杯</a></div>
                        <div class="p-extend p-extend-new"><span class=""><span class="promise ftx-03 jd-service"><span
                                class="jd-service-icon"></span><span data-tips="选服务"
                                class="ftx-03 jd-service jd-service-text"
                                clstag="pageclick|keycount|Shopcart_ExtendedService">选服务</span></span></span></div>
                      </div>
                    </div>
                  </div>
                  <div class="cell p-props">
                    <div class="props-txt"><span class="">秸秆漱口杯</span></div>
                  </div>
                  <div class="cell p-price"><span class="p-price-cont">￥27.50</span>
                    <p class="mt5"><span class=""><span class="pro-tiny-tip sales-promotion">促销<b></b></span></span></p>
                  </div>
                  <div class="cell p-quantity">
                    <div class="cart-number quantity "><button class="cart-number-dec"><i
                          class="cart-icon-subt"></i></button>
                      <div class="cart-input"><input class="cart-input-o" min="1" max="200" value="200"></div><button
                        class="cart-number-inc is-disabled" disabled=""><i class="cart-icon-add"></i></button>
                    </div>
                    <p class="ac ftx03">有货</p>
                  </div>
                  <div class="cell p-sum"><strong>¥5500.00</strong></div>
                  <div class="cell p-ops"><a href="#none" class="p-ops-item"
                      clstag="pageclick|keycount|Shopcart_Delete|5893557">删除</a><a href="#none" class="p-ops-item"
                      clstag="pageclick|keycount|Shopcart_MovetoFollow|5893557">移入关注</a></div>
                </div>
                <div class="product-extra mb10">
                  <p class="pec-item"><span class="pec-item-tit fl ftx01">【闪购】结束时间12月12日 00:00</span></p>
                </div>
                <div class="item-line"></div>
              </div>
            </div>
            <div class="item-combine">
              <div class="item-header">
                <div class="f-txt"><span class="full-icon no">换购<b></b></span><span class="vertical-gap"></span><span
                    class="ftx06 ml5">满1件可低价换购商品</span><a href="#none" class="hg-btn ftx01 ml5"
                    id="huangou_200033029584">立即换购&nbsp;&gt;</a></div>
                <div class="f-price ar">
                  <p class="ftx07">￥0.00</p>
                </div>
              </div>
              <div class="item-item " id="100005484806" data-sku="100005484806" data-ts="1592632047978"
                data-skuuuid="115484171831163614389510144">
                <div class="item-form">
                  <div class="cell p-checkbox">
                    <div class="cart-checkbox"><input type="checkbox" name="checkItem" class="jdcheckbox"
                        clstag="pageclick|keycount|Shopcart_CheckProd|0_100005484806"><span class="line-circle"></span>
                    </div>
                  </div>
                  <div class="cell p-goods">
                    <div class="goods-item">
                      <div class="p-img "><a href="//item.jd.com/100005484806.html" target="_blank" rel="noreferrer"
                          title="佳洁士(Crest) 儿童防蛀牙膏冰雪奇缘(草莓味)40克"><img
                            src="//img13.360buyimg.com/n0/s80x80_jfs/t10906/23/3274866146/216080/24fc94d4/5ce65c3eN5ee25f01.jpg.dpg"
                            alt="佳洁士(Crest) 儿童防蛀牙膏冰雪奇缘(草莓味)40克"></a></div>
                      <div class="p-msg">
                        <div class="p-name"><a href="//item.jd.com/100005484806.html" target="_blank" rel="noreferrer"
                            title="佳洁士(Crest) 儿童防蛀牙膏冰雪奇缘(草莓味)40克"
                            clstag="pageclick|keycount|Shopcart_Productid|100005484806_12"><i
                              class="product-icon JD_SUPERMARKET" title=""></i>佳洁士(Crest) 儿童防蛀牙膏冰雪奇缘(草莓味)40克</a></div>
                        <div class="p-extend p-extend-new"><span class=""><span class="promise ftx-03 jd-service"><span
                                class="jd-service-icon"></span><span data-tips="选服务"
                                class="ftx-03 jd-service jd-service-text"
                                clstag="pageclick|keycount|Shopcart_ExtendedService">选服务</span></span></span></div>
                      </div>
                    </div>
                  </div>
                  <div class="cell p-props">
                    <div class="props-txt"><span class="">儿童防蛀牙膏冰雪奇缘40g</span></div>
                  </div>
                  <div class="cell p-price"><span class="p-price-cont">￥15.90</span>
                    <p class="mt5"><span class=""><span class="pro-tiny-tip sales-promotion">促销<b></b></span></span></p>
                  </div>
                  <div class="cell p-quantity">
                    <div class="cart-number quantity "><button class="cart-number-dec"><i
                          class="cart-icon-subt"></i></button>
                      <div class="cart-input"><input class="cart-input-o" min="1" max="200" value="2"></div><button
                        class="cart-number-inc"><i class="cart-icon-add"></i></button>
                    </div>
                    <p class="ac ftx03">有货</p>
                  </div>
                  <div class="cell p-sum"><strong>¥31.80</strong></div>
                  <div class="cell p-ops"><a href="#none" class="p-ops-item"
                      clstag="pageclick|keycount|Shopcart_Delete|100005484806">删除</a><a href="#none" class="p-ops-item"
                      clstag="pageclick|keycount|Shopcart_MovetoFollow|100005484806">移入关注</a></div>
                </div>
                <div class="product-extra mb10">
                  <p class="pec-item"><span class="pec-item-tit fl ftx01 pec-item-tit-plus"><em
                        class="pec-booking-checkbox"></em>免费试用plus会员，享受商品会员价(省¥2.00元)</span><span
                      class="pec-item-tit fl"><a href="//plus.jd.com/index" class="ftx-07 ml20" target="_blank"
                        rel="noreferrer">立即试用&nbsp;&gt;</a></span></p>
                </div>
                <div class="item-line"></div>
              </div>
            </div>
            <div class="item-combine">
              <div class="item-header">
                <div class="f-txt"><span class="full-icon no">满减<b></b></span><span class="vertical-gap"></span><span
                    class="ftx06 ml5">满2件总价8折</span><a href="//search.jd.com/Search?activity_id=200029426430"
                    rel="noreferrer" target="_blank" class="ftx-05 ml5">去凑单&nbsp;&gt;</a></div>
                <div class="f-price ar">
                  <p class="ftx07">￥0.00</p>
                </div>
              </div>
              <div class="item-item " id="100000756414" data-sku="100000756414" data-ts="1592630972972"
                data-skuuuid="1127172186831159105480785920">
                <div class="item-form">
                  <div class="cell p-checkbox">
                    <div class="cart-checkbox"><input type="checkbox" name="checkItem" class="jdcheckbox"
                        clstag="pageclick|keycount|Shopcart_CheckProd|0_100000756414"><span class="line-circle"></span>
                    </div>
                  </div>
                  <div class="cell p-goods">
                    <div class="goods-item">
                      <div class="p-img "><a href="//item.jd.com/100000756414.html" target="_blank" rel="noreferrer"
                          title="舒适达抗敏感美白配方牙膏  美白护理 亮白牙齿清新口气去渍抗敏100g"><img
                            src="//img30.360buyimg.com/n0/s80x80_jfs/t1/129857/32/19201/120859/5fd09868E3e0af340/9e02952c7a3a66bb.jpg.dpg"
                            alt="舒适达抗敏感美白配方牙膏  美白护理 亮白牙齿清新口气去渍抗敏100g"></a></div>
                      <div class="p-msg">
                        <div class="p-name"><a href="//item.jd.com/100000756414.html" target="_blank" rel="noreferrer"
                            title="舒适达抗敏感美白配方牙膏  美白护理 亮白牙齿清新口气去渍抗敏100g"
                            clstag="pageclick|keycount|Shopcart_Productid|100000756414_9"><i
                              class="product-icon JD_SUPERMARKET" title=""></i>舒适达抗敏感美白配方牙膏 美白护理 亮白牙齿清新口气去渍抗敏100g</a>
                        </div>
                        <div class="p-extend p-extend-new"><span class=""><span class="promise ftx-03 jd-service"><span
                                class="jd-service-icon"></span><span data-tips="选服务"
                                class="ftx-03 jd-service jd-service-text"
                                clstag="pageclick|keycount|Shopcart_ExtendedService">选服务</span></span></span></div>
                      </div>
                    </div>
                  </div>
                  <div class="cell p-props">
                    <div class="props-txt"><span class="">美白配方 100g</span></div>
                  </div>
                  <div class="cell p-price"><span class="p-price-cont">￥27.80</span>
                    <p class="mt5"><span class=""><span class="pro-tiny-tip">比加入时降8.2元</span></span></p>
                    <p class="mt5"><span class=""><span class="pro-tiny-tip sales-promotion">促销<b></b></span></span></p>
                  </div>
                  <div class="cell p-quantity">
                    <div class="cart-number quantity "><button disabled="" class="cart-number-dec is-disabled"><i
                          class="cart-icon-subt"></i></button>
                      <div class="cart-input"><input class="cart-input-o" min="1" max="200" value="1"></div><button
                        class="cart-number-inc"><i class="cart-icon-add"></i></button>
                    </div>
                    <p class="ac ftx03">有货</p>
                  </div>
                  <div class="cell p-sum"><strong>¥27.80</strong></div>
                  <div class="cell p-ops"><a href="#none" class="p-ops-item"
                      clstag="pageclick|keycount|Shopcart_Delete|100000756414">删除</a><a href="#none" class="p-ops-item"
                      clstag="pageclick|keycount|Shopcart_MovetoFollow|100000756414">移入关注</a></div>
                </div>
                <div class="product-extra mb10"></div>
                <div class="item-line"></div>
              </div>
              <div class="item-item " id="100000670943" data-sku="100000670943" data-ts="1592630852781"
                data-skuuuid="115484227831158601387266048">
                <div class="item-form">
                  <div class="cell p-checkbox">
                    <div class="cart-checkbox"><input type="checkbox" name="checkItem" class="jdcheckbox"
                        clstag="pageclick|keycount|Shopcart_CheckProd|0_100000670943"><span class="line-circle"></span>
                    </div>
                  </div>
                  <div class="cell p-goods">
                    <div class="goods-item">
                      <div class="p-img "><a href="//item.jd.com/100000670943.html" target="_blank" rel="noreferrer"
                          title="舒适达抗敏感多效护理牙膏  坚固牙釉质 缓解牙敏感防蛀清新口气100g"><img
                            src="//img20.360buyimg.com/n0/s80x80_jfs/t1/151540/14/9266/113740/5fd09830Eea2d1206/ad8fbfc3388068ba.jpg.dpg"
                            alt="舒适达抗敏感多效护理牙膏  坚固牙釉质 缓解牙敏感防蛀清新口气100g"></a></div>
                      <div class="p-msg">
                        <div class="p-name"><a href="//item.jd.com/100000670943.html" target="_blank" rel="noreferrer"
                            title="舒适达抗敏感多效护理牙膏  坚固牙釉质 缓解牙敏感防蛀清新口气100g"
                            clstag="pageclick|keycount|Shopcart_Productid|100000670943_9"><i
                              class="product-icon JD_SUPERMARKET" title=""></i>舒适达抗敏感多效护理牙膏 坚固牙釉质 缓解牙敏感防蛀清新口气100g</a>
                        </div>
                        <div class="p-extend p-extend-new"><span class=""><span class="promise ftx-03 jd-service"><span
                                class="jd-service-icon"></span><span data-tips="选服务"
                                class="ftx-03 jd-service jd-service-text"
                                clstag="pageclick|keycount|Shopcart_ExtendedService">选服务</span></span></span></div>
                      </div>
                    </div>
                  </div>
                  <div class="cell p-props">
                    <div class="props-txt"><span class="">多效护理 100g</span></div>
                  </div>
                  <div class="cell p-price"><span class="p-price-cont">￥30.00</span>
                    <p class="mt5"><span class=""><span class="pro-tiny-tip">比加入时降6元</span></span></p>
                    <p class="mt5"><span class=""><span class="pro-tiny-tip sales-promotion">促销<b></b></span></span></p>
                  </div>
                  <div class="cell p-quantity">
                    <div class="cart-number quantity "><button disabled="" class="cart-number-dec is-disabled"><i
                          class="cart-icon-subt"></i></button>
                      <div class="cart-input"><input class="cart-input-o" min="1" max="3" value="1"></div><button
                        class="cart-number-inc"><i class="cart-icon-add"></i></button>
                    </div>
                    <p class="ac ftx01">仅剩3件</p>
                  </div>
                  <div class="cell p-sum"><strong>¥30.00</strong></div>
                  <div class="cell p-ops"><a href="#none" class="p-ops-item"
                      clstag="pageclick|keycount|Shopcart_Delete|100000670943">删除</a><a href="#none" class="p-ops-item"
                      clstag="pageclick|keycount|Shopcart_MovetoFollow|100000670943">移入关注</a></div>
                </div>
                <div class="product-extra mb10"></div>
                <div class="item-line"></div>
              </div>
            </div>
            <div class="item-item " id="100010670854" data-sku="100010670854" data-ts="1592629754318"
              data-skuuuid="1017515731831153994098159616">
              <div class="item-form">
                <div class="cell p-checkbox">
                  <div class="cart-checkbox"><input type="checkbox" name="checkItem" class="jdcheckbox"
                      clstag="pageclick|keycount|Shopcart_CheckProd|0_100010670854"><span class="line-circle"></span>
                  </div>
                </div>
                <div class="cell p-goods">
                  <div class="goods-item">
                    <div class="p-img "><a href="//item.jd.com/100010670854.html" target="_blank" rel="noreferrer"
                        title="美澌嘉 泡泡机抖音同款电动泡泡相机带灯光音乐防漏水卡通小猪泡泡相枪（含2瓶泡泡水）"><img
                          src="//img13.360buyimg.com/n0/s80x80_jfs/t1/136288/25/19135/100309/5fd17538E0f2a4f9d/217d9cf371ed2cf9.jpg.dpg"
                          alt="美澌嘉 泡泡机抖音同款电动泡泡相机带灯光音乐防漏水卡通小猪泡泡相枪（含2瓶泡泡水）"></a></div>
                    <div class="p-msg">
                      <div class="p-name"><a href="//item.jd.com/100010670854.html" target="_blank" rel="noreferrer"
                          title="美澌嘉 泡泡机抖音同款电动泡泡相机带灯光音乐防漏水卡通小猪泡泡相枪（含2瓶泡泡水）"
                          clstag="pageclick|keycount|Shopcart_Productid|100010670854_1"><i
                            class="product-icon JD_SUPERMARKET" title=""></i>美澌嘉
                          泡泡机抖音同款电动泡泡相机带灯光音乐防漏水卡通小猪泡泡相枪（含2瓶泡泡水）</a></div>
                      <div class="p-extend p-extend-new"></div>
                    </div>
                  </div>
                </div>
                <div class="cell p-props">
                  <div class="props-txt"><span class="">小猪泡泡相机（含35ml*2泡泡水）</span></div>
                </div>
                <div class="cell p-price"><span class="p-price-cont">￥26.80</span>
                  <p class="mt5"><span class=""><span class="pro-tiny-tip">比加入时降3.1元</span></span></p>
                  <p class="mt5"></p>
                </div>
                <div class="cell p-quantity">
                  <div class="cart-number quantity "><button disabled="" class="cart-number-dec is-disabled"><i
                        class="cart-icon-subt"></i></button>
                    <div class="cart-input"><input class="cart-input-o" min="1" max="200" value="1"></div><button
                      class="cart-number-inc"><i class="cart-icon-add"></i></button>
                  </div>
                  <p class="ac ftx03">有货</p>
                </div>
                <div class="cell p-sum"><strong>¥26.80</strong></div>
                <div class="cell p-ops"><a href="#none" class="p-ops-item"
                    clstag="pageclick|keycount|Shopcart_Delete|100010670854">删除</a><a href="#none" class="p-ops-item"
                    clstag="pageclick|keycount|Shopcart_MovetoFollow|100010670854">移入关注</a></div>
              </div>
              <div class="product-extra mb10">
                <p class="pec-item"><a class="pec-item-tit fl ftx10 pec-item-gift"
                    href="//item.jd.com/100011187152.html" title="【赠品】美澌嘉 MSj 泡泡机用电池玩具配件1047号3节5号电池"
                    clstag="pageclick|keycount|Shopcart_Productid|100011187152_2}">【赠品】美澌嘉 MSj
                    泡泡机用电池玩具配件1047号3节5号电池</a><span class="pec-item-num fl">X1</span><a class="fl ml10 ftx07"
                    href="//item.jd.com/100011187152.html" target="_blank" rel="noreferrer">查看价格</a></p>
              </div>
              <div class="item-line"></div>
            </div>
            <div class="item-combine">
              <div class="item-header">
                <div class="f-txt"><span class="full-icon no">换购<b></b></span><span class="vertical-gap"></span><span
                    class="ftx06 ml5">满1件可低价换购商品</span><a href="#none" class="hg-btn ftx01 ml5"
                    id="huangou_200034198492">立即换购&nbsp;&gt;</a></div>
                <div class="f-price ar">
                  <p class="ftx07">￥0.00</p>
                </div>
              </div>
              <div class="item-item " id="100010875248" data-sku="100010875248" data-ts="1592629559933"
                data-skuuuid="1018116238831153178817548288">
                <div class="item-form">
                  <div class="cell p-checkbox">
                    <div class="cart-checkbox"><input type="checkbox" name="checkItem" class="jdcheckbox"
                        clstag="pageclick|keycount|Shopcart_CheckProd|0_100010875248"><span class="line-circle"></span>
                    </div>
                  </div>
                  <div class="cell p-goods">
                    <div class="goods-item">
                      <div class="p-img "><a href="//item.jd.com/100010875248.html" target="_blank" rel="noreferrer"
                          title="小浣熊 儿童电动牙刷 宝宝儿童牙刷声波震动防水(自带杜邦刷毛刷头*2+万根软毛刷头)"><img
                            src="//img14.360buyimg.com/n0/s80x80_jfs/t1/153660/22/9197/112122/5fd18559E771ed926/d85f7a084e034da6.jpg.dpg"
                            alt="小浣熊 儿童电动牙刷 宝宝儿童牙刷声波震动防水(自带杜邦刷毛刷头*2+万根软毛刷头)"></a></div>
                      <div class="p-msg">
                        <div class="p-name"><a href="//item.jd.com/100010875248.html" target="_blank" rel="noreferrer"
                            title="小浣熊 儿童电动牙刷 宝宝儿童牙刷声波震动防水(自带杜邦刷毛刷头*2+万根软毛刷头)"
                            clstag="pageclick|keycount|Shopcart_Productid|100010875248_12"><i
                              class="product-icon JD_SUPERMARKET" title=""></i>小浣熊 儿童电动牙刷
                            宝宝儿童牙刷声波震动防水(自带杜邦刷毛刷头*2+万根软毛刷头)</a></div>
                        <div class="p-extend p-extend-new"></div>
                      </div>
                    </div>
                  </div>
                  <div class="cell p-props">
                    <div class="props-txt"><span class="">电动牙刷</span></div>
                  </div>
                  <div class="cell p-price"><span class="p-price-cont">￥39.90</span>
                    <p class="mt5"><span class=""><span class="pro-tiny-tip">京贴每满300减40</span></span></p>
                    <p class="mt5"><span class=""><span class="pro-tiny-tip">比加入时降10元</span></span></p>
                    <p class="mt5"><span class=""><span class="pro-tiny-tip sales-promotion">促销<b></b></span></span></p>
                  </div>
                  <div class="cell p-quantity">
                    <div class="cart-number quantity "><button disabled="" class="cart-number-dec is-disabled"><i
                          class="cart-icon-subt"></i></button>
                      <div class="cart-input"><input class="cart-input-o" min="1" max="200" value="1"></div><button
                        class="cart-number-inc"><i class="cart-icon-add"></i></button>
                    </div>
                    <p class="ac ftx03">有货</p>
                  </div>
                  <div class="cell p-sum"><strong>¥39.90</strong></div>
                  <div class="cell p-ops"><a href="#none" class="p-ops-item"
                      clstag="pageclick|keycount|Shopcart_Delete|100010875248">删除</a><a href="#none" class="p-ops-item"
                      clstag="pageclick|keycount|Shopcart_MovetoFollow|100010875248">移入关注</a></div>
                </div>
                <div class="product-extra mb10"></div>
                <div class="item-line"></div>
              </div>
            </div>
            <div class="item-item " id="3596703" data-sku="3596703" data-ts="1568536639818"
              data-skuuuid="10172471820760465512366082">
              <div class="item-form">
                <div class="cell p-checkbox">
                  <div class="cart-checkbox"><input type="checkbox" name="checkItem" class="jdcheckbox"
                      clstag="pageclick|keycount|Shopcart_CheckProd|0_3596703"><span class="line-circle"></span></div>
                </div>
                <div class="cell p-goods">
                  <div class="goods-item">
                    <div class="p-img "><a href="//item.jd.com/3596703.html" target="_blank" rel="noreferrer"
                        title="海底捞 火锅底料 浓香牛油 火锅调味品 手工全型 一料多用巴蜀川香味火锅食材500g"><img
                          src="//img20.360buyimg.com/n0/s80x80_jfs/t1/132537/14/16184/145378/5fb4d239E54be6ead/1bb205b51b108d07.jpg.dpg"
                          alt="海底捞 火锅底料 浓香牛油 火锅调味品 手工全型 一料多用巴蜀川香味火锅食材500g"></a></div>
                    <div class="p-msg">
                      <div class="p-name"><a href="//item.jd.com/3596703.html" target="_blank" rel="noreferrer"
                          title="海底捞 火锅底料 浓香牛油 火锅调味品 手工全型 一料多用巴蜀川香味火锅食材500g"
                          clstag="pageclick|keycount|Shopcart_Productid|3596703_1"><i
                            class="product-icon JD_SUPERMARKET" title=""></i>海底捞 火锅底料 浓香牛油 火锅调味品 手工全型
                          一料多用巴蜀川香味火锅食材500g</a></div>
                      <div class="p-extend p-extend-new"></div>
                    </div>
                  </div>
                </div>
                <div class="cell p-props">
                  <div class="props-txt"><span class="">浓香手工牛油500g</span></div>
                </div>
                <div class="cell p-price"><span class="p-price-cont">￥23.90</span>
                  <p class="mt5"><span class=""><span class="pro-tiny-tip">限购7件</span></span></p>
                  <p class="mt5"><span class=""><span class="pro-tiny-tip">比加入时降1元</span></span></p>
                  <p class="mt5"></p>
                </div>
                <div class="cell p-quantity">
                  <div class="cart-number quantity "><button disabled="" class="cart-number-dec is-disabled"><i
                        class="cart-icon-subt"></i></button>
                    <div class="cart-input"><input class="cart-input-o" min="1" max="7" value="1"></div><button
                      class="cart-number-inc"><i class="cart-icon-add"></i></button>
                  </div>
                  <p class="ac ftx03">有货</p>
                </div>
                <div class="cell p-sum"><strong>¥23.90</strong></div>
                <div class="cell p-ops"><a href="#none" class="p-ops-item"
                    clstag="pageclick|keycount|Shopcart_Delete|3596703">删除</a><a href="#none" class="p-ops-item"
                    clstag="pageclick|keycount|Shopcart_MovetoFollow|3596703">移入关注</a></div>
              </div>
              <div class="product-extra mb10"></div>
              <div class="item-line"></div>
            </div>
            <div class="item-item " id="858419" data-sku="858419" data-ts="1568536567323"
              data-skuuuid="10172471820760465512366081">
              <div class="item-form">
                <div class="cell p-checkbox">
                  <div class="cart-checkbox"><input type="checkbox" name="checkItem" class="jdcheckbox"
                      clstag="pageclick|keycount|Shopcart_CheckProd|0_858419"><span class="line-circle"></span></div>
                </div>
                <div class="cell p-goods">
                  <div class="goods-item">
                    <div class="p-img "><a href="//item.jd.com/858419.html" target="_blank" rel="noreferrer"
                        title="海底捞 火锅底料 鲜美番茄 火锅调味品 一料多用酸香味火锅食材3~5人份200g"><img
                          src="//img12.360buyimg.com/n0/s80x80_jfs/t1/144579/12/11775/132989/5f924ed5E0383266a/8553bd9c36f41c15.jpg.dpg"
                          alt="海底捞 火锅底料 鲜美番茄 火锅调味品 一料多用酸香味火锅食材3~5人份200g"></a></div>
                    <div class="p-msg">
                      <div class="p-name"><a href="//item.jd.com/858419.html" target="_blank" rel="noreferrer"
                          title="海底捞 火锅底料 鲜美番茄 火锅调味品 一料多用酸香味火锅食材3~5人份200g"
                          clstag="pageclick|keycount|Shopcart_Productid|858419_1"><i class="product-icon JD_SUPERMARKET"
                            title=""></i>海底捞 火锅底料 鲜美番茄 火锅调味品 一料多用酸香味火锅食材3~5人份200g</a></div>
                      <div class="p-extend p-extend-new"></div>
                    </div>
                  </div>
                </div>
                <div class="cell p-props">
                  <div class="props-txt"><span class="">番茄底料200g</span></div>
                </div>
                <div class="cell p-price"><span class="p-price-cont">￥12.90</span>
                  <p class="mt5"><span class=""><span class="pro-tiny-tip">限购15件</span></span></p>
                  <p class="mt5"><span class=""><span class="pro-tiny-tip">比加入时降1元</span></span></p>
                  <p class="mt5"></p>
                </div>
                <div class="cell p-quantity">
                  <div class="cart-number quantity "><button disabled="" class="cart-number-dec is-disabled"><i
                        class="cart-icon-subt"></i></button>
                    <div class="cart-input"><input class="cart-input-o" min="1" max="15" value="1"></div><button
                      class="cart-number-inc"><i class="cart-icon-add"></i></button>
                  </div>
                  <p class="ac ftx03">有货</p>
                </div>
                <div class="cell p-sum"><strong>¥12.90</strong></div>
                <div class="cell p-ops"><a href="#none" class="p-ops-item"
                    clstag="pageclick|keycount|Shopcart_Delete|858419">删除</a><a href="#none" class="p-ops-item"
                    clstag="pageclick|keycount|Shopcart_MovetoFollow|858419">移入关注</a></div>
              </div>
              <div class="product-extra mb10"></div>
              <div class="item-line"></div>
            </div>
            <div class="item-combine">
              <div class="item-header">
                <div class="f-txt"><span class="full-icon no">换购<b></b></span><span class="vertical-gap"></span><span
                    class="ftx06 ml5">满1件可低价换购商品</span><a href="#none" class="hg-btn ftx01 ml5"
                    id="huangou_200026703521">立即换购&nbsp;&gt;</a></div>
                <div class="f-price ar">
                  <p class="ftx07">￥0.00</p>
                </div>
              </div>
              <div class="item-item " id="5572930" data-sku="5572930" data-ts="1560866094934"
                data-skuuuid="10172471820760465512366080">
                <div class="item-form">
                  <div class="cell p-checkbox">
                    <div class="cart-checkbox"><input type="checkbox" name="checkItem" class="jdcheckbox"
                        clstag="pageclick|keycount|Shopcart_CheckProd|0_5572930"><span class="line-circle"></span></div>
                  </div>
                  <div class="cell p-goods">
                    <div class="goods-item">
                      <div class="p-img "><a href="//item.jd.com/5572930.html" target="_blank" rel="noreferrer"
                          title="飞利浦（PHILIPS）男士电动剃须刀全身水洗干湿双剃剃胡刀胡须刀刮胡刀S1050/02"><img
                            src="//img30.360buyimg.com/n0/s80x80_jfs/t1/132048/20/19280/138630/5fcf6172E674a5c84/0b6eb89583536444.jpg.dpg"
                            alt="飞利浦（PHILIPS）男士电动剃须刀全身水洗干湿双剃剃胡刀胡须刀刮胡刀S1050/02"></a></div>
                      <div class="p-msg">
                        <div class="p-name"><a href="//item.jd.com/5572930.html" target="_blank" rel="noreferrer"
                            title="飞利浦（PHILIPS）男士电动剃须刀全身水洗干湿双剃剃胡刀胡须刀刮胡刀S1050/02"
                            clstag="pageclick|keycount|Shopcart_Productid|5572930_12"><i class="product-icon "
                              title=""></i><i class="product-icon JD_JIADIAN"
                              title=""></i>飞利浦（PHILIPS）男士电动剃须刀全身水洗干湿双剃剃胡刀胡须刀刮胡刀S1050/02</a></div>
                        <div class="p-extend p-extend-new"><span class=""><span class="promise ftx-03 jd-service"><span
                                class="jd-service-icon"></span><span data-tips="选服务"
                                class="ftx-03 jd-service jd-service-text"
                                clstag="pageclick|keycount|Shopcart_ExtendedService">选服务</span></span></span><span
                            class="promise jd-giftcard" data-giftcard="5572930"><i class="jd-giftcard-icon"></i><span
                              title="精美礼品包装服务"
                              class="jd-service-text ftx-03 gift-packing jd-giftcard-text">礼品包装</span></span></div>
                      </div>
                    </div>
                  </div>
                  <div class="cell p-props">
                    <div class="props-txt"><span class="">1系干湿双剃 进口刀头款</span></div>
                  </div>
                  <div class="cell p-price"><span class="p-price-cont">￥199.00</span>
                    <p class="mt5"><span class=""><span class="pro-tiny-tip">京贴每满300减40</span></span></p>
                    <p class="mt5"><span class=""><span class="pro-tiny-tip sales-promotion">促销<b></b></span></span></p>
                  </div>
                  <div class="cell p-quantity">
                    <div class="cart-number quantity "><button disabled="" class="cart-number-dec is-disabled"><i
                          class="cart-icon-subt"></i></button>
                      <div class="cart-input"><input class="cart-input-o" min="1" max="200" value="1"></div><button
                        class="cart-number-inc"><i class="cart-icon-add"></i></button>
                    </div>
                    <p class="ac ftx03">有货</p>
                  </div>
                  <div class="cell p-sum"><strong>¥199.00</strong></div>
                  <div class="cell p-ops"><a href="#none" class="p-ops-item"
                      clstag="pageclick|keycount|Shopcart_Delete|5572930">删除</a><a href="#none" class="p-ops-item"
                      clstag="pageclick|keycount|Shopcart_MovetoFollow|5572930">移入关注</a></div>
                </div>
                <div class="product-extra mb10">
                  <div class="lg-countdown ">
                    <p class="pec-item"><span class="pec-item-tit fl ftx01 booking-item">抢购中，距结束还剩<em
                          class="booking-item__time">07</em>:<em class="booking-item__time">38</em>:<em
                          class="booking-item__time">27</em></span></p>
                  </div>
                </div>
                <div class="item-line"></div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div style="padding-bottom: 52px;"></div>
            <div class="cart-floatbar cart-floatbar-fixed"
              style="position: fixed; transform: translateZ(0px); top: auto; bottom: 0px;">
              <div class="cart-floatbar">
                <div class="cart-toolbar">
                  <div class="toolbar-wrap"><input type="hidden" id="checkedCartState" value="1">
                    <div class="selected-item-list hide" style="display: none;"></div>
                    <div class="options-box">
                      <div class="left">
                        <div class="select-all"><input type="checkbox" class="jdcheckbox"
                            clstag="pageclick|keycount|Shopcart_CheckAll|0">全选</div>
                        <div class="operation"><a href="#none" class="opt-batch-remove">删除选中的商品</a><a href="#none"
                            class="opt-batch-follow">移入关注</a><a href="#none" class="opt-cleaner"
                            title="亲，点我可快速清理购物车商品哦！"><strong id="J_opt_cart_cleaner">清理购物车</strong></a></div>
                      </div>
                      <div class="right">
                        <div class="combine">
                          <div class="btn-area"><a class="common-submit-btn" href="#none"
                              clstag="pageclick|keycount|Shopcart_Pay|0">去结算<b></b></a></div>
                          <div class="price-sum">
                            <div>
                              <div class="price-show"><span class="txt">总价：</span><span
                                  class="price priceShow"><em>￥5,959.00</em></span><b class="ml5 price-tips"></b>
                                <div class="price-tipsbox-new">
                                  <div class="ui-tips-main">不含运费及送装服务费</div><span class="price-tipsbox-arrow"></span>
                                </div>
                              </div><span class="amount-sum">已选择<em>201</em>件商品<b class="up"></b></span><br>
                              <div class="price-sum-extra"></div>
                            </div>
                          </div>
                          <div class="clr"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cart-removed">
          <div class="tit">已删除商品，您可以重新购买或加关注：</div>
          <div>
            <div class="cart-removed-item">
              <div class="name"><a href="//item.jd.com/1063338.html">洁婷(ladycare)棉柔卫生巾姨妈巾日用无护翼透气迷你180mm*20片(无香味）</a>
              </div>
              <div class="price"><strong>￥8.60</strong></div>
              <div class="quantity">1</div>
              <div class="ops"><a href="#none" class="mr10">重新购买</a><a href="#none">移入关注</a></div>
            </div>
          </div>
        </div>
        <div class="clr"></div>
      </div>
    </div>
    <div class="clearfix"></div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';
import {
  productgetcustomdetail,
  productgetspecialproductlist,
} from '@/api/product';
export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  methods: {},
  watch: {},
  mounted() {},
  created() {
    this.id = this.$route.query.id;
    //sthis.onproductgetcustomdetail();
  },
};
</script>
<style  scoped >
body {
  background-color: #fff;
}
img {
  border: 0;
  vertical-align: middle;
}
.ftx-02,
.ftx02 {
  color: #71b247;
}
.clearfix {
  clear: both;
}
.content {
  width: 100%;
  margin: 0 auto;
  min-height: 700px;
  background-color: #fff;
}
.content .wrap-w {
  width: 900px;
  margin: 0 auto;
}
.cart-filter-bar {
  margin-top: 10px;
  position: relative;
  z-index: 100;
  width: 100%;
  height: 40px;
}
.cart-filter-bar ul.switch-cart {
  float: left;
  position: relative;
  overflow: hidden;
}
.cart-filter-bar li.switch-cart-item {
  float: left;
  position: relative;
  font-weight: 700;
  font-size: 16px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  padding: 0 0 10px;
  cursor: pointer;
  margin: 5px 30px 0 0;
}
.cart-filter-bar li.switch-cart-item:first-child em {
  color: #e2231a;
}
.cart-filter-bar li.switch-cart-item:first-child .number {
  color: #e2231a;
  font-size: 14px;
  margin-left: 5px;
}
.cart-filter-bar .cart-store {
    float: right;
    position: relative;
    z-index: 5;
    margin-bottom: 10px;
    padding-left: 122px;
}.cart-filter-bar .cart-store .label {
    float: left;
    line-height: 26px;
}.cart-filter-bar .cart-store #jdarea {
    position: relative;
    float: left;
    z-index: 3;
    height: 26px;
    cursor: default;
    margin: 0;
}.ui-area-wrap .ui-area-text-wrap {
    float: left;
    position: relative;
    top: 0;
    height: 23px;
    background: #fff;
    border: 1px solid #CECBCE;
    padding: 0 24px 0 4px;
    line-height: 23px;
    overflow: hidden;
}
.ui-area-wrap .ui-area-content-wrap {
     display: none; 
    float: left;
    position: absolute;
    top: 25px;
    left: 0;
    border: 1px solid #CECBCE;
    width: 390px;
    padding: 12px 12px 15px;
    background: #fff;
    -webkit-box-shadow: 0 0 5px #ddd;
    box-shadow: 0 0 5px #ddd;
}
.ui-area-wrap .ui-area-content-wrap.ui-area-w-max {
    width: 460px;
}
.ui-area-wrap .ui-area-content-wrap .ui-area-close {
    position: absolute;
    z-index: 2;
    top: 12px;
    right: 12px;
    width: 17px;
    height: 17px;
    background: url(//img12.360buyimg.com/uba/jfs/t4660/73/4024943067/1159/f1a90f68/59088392N8b6279bb.png) no-repeat 3px -124px;
    cursor: pointer;
}.ui-area-wrap .ui-area-content-wrap .ui-area-close, .ui-area-wrap .ui-area-text-wrap b {
    background-repeat: no-repeat;
}.ui-area-wrap .ui-area-content-wrap.ui-area-common-area-mode .ui-area-close {
    top: 10px;
    right: 33px;
}.ui-area-wrap .ui-area-content-wrap .ui-area-common-wrap {
    margin-bottom: 12px;
    padding-bottom: 6px;
    border-bottom: 1px solid #f7f7f7;
}.ui-area-wrap .ui-area-content-wrap .ui-area-module-title {
    color: #666;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 6px;
    position: relative;
}.ui-area-wrap .ui-area-content-wrap .ui-area-common-wrap .ui-area-common-content {
    height: 20px;
    overflow: hidden;
}.ui-area-wrap .ui-area-content-wrap .ui-area-common-wrap .ui-area-common-content li {
    height: 18px;
    padding: 2px 0;
}.ui-area-wrap .ui-area-content-wrap .ui-area-common-wrap .ui-area-common-content li a {
    color: #666;
}.ui-area-wrap .ui-area-content-wrap .ui-area-common-wrap .ui-area-common-content li a span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    *display: inline;
    *zoom: 1;
}.ui-area-wrap .ui-area-content-wrap .ui-area-common-wrap .ui-area-common-content li a span.ui-area-common-c-i-l {
    width: 60px;
}
</style>
